import loadable from "@loadable/component";
import classnames from "classnames";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { mediaServerCatalogImage } from "@inferno/renderer-shared-core";
import { PodcastSizes, PodcastSrcSet, Sizes, SrcSet } from "@inferno/renderer-shared-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import type { BlockFragment, PodcastFragment } from "@ihr-radioedit/inferno-webapi";
import PodcastsEpisodeCollection from "./PodcastsEpisodeCollection.component";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { getPodcastDisplayHints } from "../../services/Sites.utils";
import { Container, Grid, Heading } from "../../ui";
import type { Store } from "@inferno/renderer-shared-core";
import "./Podcasts.style.scss";

const PodcastsCollection = loadable(() => import("./PodcastsCollection.component"));
const ImageTile = loadable(() => import("../../ui/ImageTile.component"));

export const generatePodcastProps = ({
  host,
  podcast,
  context,
  store,
  translator,
}: {
  host: string;
  podcast: PodcastFragment;
  context?: any;
  store: Store;
  translator: UseTranslationResponse<"translation">;
}) => {
  const { t } = translator;

  const img = mediaServerCatalogImage(host, "podcast", podcast.id, 48, 48, true);
  const srcset: SrcSet[] = PodcastSrcSet(img);
  const sizes: Sizes[] = PodcastSizes();
  const placeholderSrcset: SrcSet[] = PodcastSrcSet(placeholderImage);
  const podcastProps = {
    title: podcast.title,
    description: podcast.description,
    url: `${store.env.NATIONAL_SITE_URL}/podcast/${podcast.slug}-${podcast.id}/`,
    imageUrl: `${img}?ops=max(270,270),quality(70)`,
    responsiveImg: {
      src: `${img}?ops=max(270,270),quality(70)`,
      alt: t("podcast_alt", { title: podcast.title }),
      srcset,
      sizes,
      placeholderSrcset,
      initialWidth: 150,
      initialHeight: 150,
    },
    context,
  };

  return podcastProps;
};

interface PodcastsProps {
  block: BlockFragment;
  podcasts: PodcastFragment[];
  store?: Store;
}

export const Podcasts = inject("store")(({ block, podcasts, store }: PodcastsProps) => {
  if (!podcasts || !store) {
    return null;
  }

  const { tags } = block;
  const { site, env } = store;
  const { t } = useTranslation();
  const podcastLink = reverseRoute(site, "podcasts");
  useEffect(() => {
    store.storeBlock(block);
  });

  const css = classnames("component-podcasts", {
    [`themed-block`]: tags?.includes("display-hints/themed-block"),
  });

  const headingLink = {
    text: t("view_more"),
    label: `${t("view_podcast")}`,
    url: podcastLink || "/",
  };

  const displayHints = getPodcastDisplayHints(block.tags || []);

  if (displayHints.isPodcastCollection || displayHints.isHorizontalLayout) {
    return displayHints.isPodcastEpisodeCollection ? (
      <PodcastsEpisodeCollection
        block={block}
        podcasts={podcasts}
        columns={displayHints.columns}
        isHorizontalLayout={displayHints.isHorizontalLayout}
      />
    ) : (
      <PodcastsCollection
        block={block}
        podcasts={podcasts}
        columns={displayHints.columns}
        isHorizontalLayout={displayHints.isHorizontalLayout}
      />
    );
  }
  // this will render homepage presentation showing only first 4 OR showing only one podcast
  return (
    <Container className={css}>
      <Heading level={2} link={displayHints.showViewMore ? headingLink : undefined}>
        {!!store.site && `${store.site.sections.general?.name}`} Podcasts
      </Heading>
      <Grid columns={displayHints.columns}>
        {podcasts.slice(0, displayHints.columns).map((podcast, i) => (
          <ImageTile
            key={i}
            {...generatePodcastProps({
              host: env.IMAGE_HOST,
              podcast,
              context: `podcast_${i}`,
              store,
              translator: useTranslation(),
            })}
          />
        ))}
      </Grid>
    </Container>
  );
});

export default Podcasts;
