import { inject, observer } from "mobx-react";
import { Fragment, useMemo, useState } from "react";
import DOMPurify from "isomorphic-dompurify";
import { CatalogKindTypes, placeholderImage } from "@ihr-radioedit/inferno-core";
import { ResponsiveImage, ResponsiveImageProps } from "../../components/ResponsiveImage.component";
import styles from "./PodcastEpisode.module.scss";
import type { Store } from "@inferno/renderer-shared-core";
import { PlayIcon } from "../../components/icons/PlayIcon.component";
import { StopIcon } from "../../components/icons/StopIcon.component";
import { ArrowIcon } from "../../components/icons/ArrowIcon.component";
import { Spinner } from "../../ui";
import DateDisplay from "../../components/DateDisplay.component";
import { DATETIME_DISPLAY_FORMATS } from "@inferno/renderer-shared-core";
import { useTranslation } from "react-i18next";
import { getLang } from "@ihr-radioedit/inferno-core";

const PodcastEpisodeListenNow = ({
  episodeMatch,
  inProgress,
  playback,
  startText,
  stopText,
  showText,
}: {
  inProgress: boolean;
  episodeMatch: boolean;
  playback: boolean;
  startText: string;
  stopText: string;
  showText: boolean;
}) => {
  const listenState = (comp: () => JSX.Element, buttonText: string) => (
    <Fragment>
      {comp()}
      {showText && <figcaption className={styles.listenNowText}>{buttonText}</figcaption>}
    </Fragment>
  );

  if (!episodeMatch) {
    return listenState(PlayIcon, startText);
  }

  if (inProgress) {
    return <Spinner visible={true} />;
  }

  return playback ? listenState(StopIcon, stopText) : listenState(PlayIcon, startText);
};

export interface PodcastEpisodeProps {
  showDescription?: boolean;
  hasToggle: boolean;
  related_id?: number;
  id: number;
  slug: string;
  store: Store;
  url: string;
  imageUrl: string;
  title: string;
  description: string;
  responsiveImg?: ResponsiveImageProps | null;
  startDate?: number;
}

export const PodcastEpisode = inject("store")(
  observer((props: PodcastEpisodeProps) => {
    const image = props.responsiveImg ? (
      <ResponsiveImage {...props.responsiveImg} />
    ) : (
      <img src={placeholderImage} data-src={props.imageUrl} alt={props.title} className="lazyload" />
    );

    const { playback, inProgress, overrideId } = props.store.player;

    const episodeMatch = useMemo(
      () => overrideId === props.related_id || overrideId === props.slug,
      [overrideId, props.related_id, props.slug],
    );

    const sanitizedData = () => ({
      __html: DOMPurify.sanitize(props.description),
    });

    const [isVisible, setVisibility] = useState<boolean>(props.showDescription ?? true);
    const { t } = useTranslation();
    const { site, env } = props.store;

    const toggleDescription = () => {
      setVisibility(!isVisible);
    };

    const handlePlayback = () => {
      props.store.player.togglePlayback(props.store, {
        slug: props.slug,
        sectionName: "podcast-episode",
        override: {
          id: props.id,
          related_id: props.related_id,
          kind: CatalogKindTypes.podcast,
        },
      });
    };
    const language = getLang(props.store.site);
    const dateFormat =
      language === "es"
        ? DATETIME_DISPLAY_FORMATS.SHORT_DAY_FULL_MONTH_FULL_YEAR
        : DATETIME_DISPLAY_FORMATS.FULL_MONTH_SHORT_DAY_FULL_YEAR;

    return (
      <div className={`${styles.episode} square-thumb elevation-01`}>
        <div className={styles.playThumbnail}>
          <button className={styles.playTrigger} onClick={handlePlayback} aria-label="Toggle Playback">
            <span className={styles.hoverIcon}>
              <PlayIcon />
            </span>
            <figure className={styles.thumbnail}>{image}</figure>
          </button>
        </div>
        <div className={styles.descriptionWrapper}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title}>{props.title}</h2>
            {props.hasToggle && (
              <button className={styles.toggleDescription} onClick={toggleDescription} aria-label="Toggle Description">
                {isVisible ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
              </button>
            )}
          </div>
          <div className={`${styles.accordion}  ${isVisible ? "visible" : ""}`}>
            {props.description && (
              <div
                className={styles.descriptionText}
                aria-hidden={isVisible}
                tabIndex={isVisible ? 1 : -1}
                key={props.related_id}
                dangerouslySetInnerHTML={sanitizedData()}
              />
            )}
            {props.startDate && (
              <DateDisplay
                datetime={props.startDate}
                dateFormat={dateFormat}
                timezone={site?.index.timeZone || env.DEFAULT_TIMEZONE}
                language={language}
              />
            )}
          </div>
        </div>
        <button
          className={`${styles.playTrigger} ${styles.playButton}`}
          onClick={handlePlayback}
          aria-label="Toggle Playback"
        >
          <figure className={`${styles.listenNow} ${isVisible ? styles.pill : ""}`}>
            <PodcastEpisodeListenNow
              episodeMatch={episodeMatch}
              playback={playback}
              inProgress={inProgress}
              startText={t("listen")}
              stopText={t("listen_stop_mob")}
              showText={isVisible}
            />
          </figure>
        </button>
      </div>
    );
  }),
);
