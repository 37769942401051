import { inject } from "mobx-react";
import classnames from "classnames";
import { Fragment, useEffect } from "react";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";
import type { BlockFragment, PodcastEpisodeFragment, PodcastFragment } from "@ihr-radioedit/inferno-webapi";
import { Remote } from "../../components/remote/Remote.component";
import { Container, Grid, ImageTile } from "../../ui";
import { GridColumnValues } from "../../ui/Grid.component";
import { PodcastEpisode } from "./PodcastEpisode.component";
import "./PodcastsCollection.style.scss";
import { getPodcastEpisodes } from "../../services/Podcast";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { mediaServerCatalogImage } from "@inferno/renderer-shared-core";
import { PodcastSizes, PodcastSrcSet, Sizes, SrcSet } from "@inferno/renderer-shared-core";

export const generateEpisodeProps = ({
  host,
  episode,
  context,
  store,
  translator,
}: {
  host: string;
  episode: PodcastEpisodeFragment;
  context?: any;
  store: Store;
  translator: UseTranslationResponse<"translation">;
}) => {
  const { t } = translator;

  const img = mediaServerCatalogImage(host, "podcast", episode.podcastId, 48, 48, true);
  const srcset: SrcSet[] = PodcastSrcSet(img);
  const sizes: Sizes[] = PodcastSizes();
  const placeholderSrcset: SrcSet[] = PodcastSrcSet(placeholderImage);
  return {
    title: episode.title,
    description: episode.description,
    url: `${store.env.NATIONAL_SITE_URL}/podcast/${episode.podcastSlug}-${episode.podcastId}/`,
    imageUrl: `${img}?ops=max(270,270),quality(70)`,
    responsiveImg: {
      src: `${img}?ops=max(270,270),quality(70)`,
      alt: t("podcast_alt", { title: episode.title }),
      srcset,
      sizes,
      placeholderSrcset,
      initialWidth: 150,
      initialHeight: 150,
    },
    context,
    startDate: episode.startDate,
  };
};

interface PodcastsEpiSodeCollectionProps {
  block: BlockFragment;
  podcasts: PodcastFragment[];
  columns: GridColumnValues;
  isHorizontalLayout?: boolean;
  store?: Store;
}

const PodcastsEpiSodeCollection = inject("store")((props: PodcastsEpiSodeCollectionProps) => {
  const translator = useTranslation();
  if (!props.store || !props.podcasts) {
    return null;
  }
  const { store, block } = props;
  const { microsite, site } = store;
  useEffect(() => {
    store.storeBlock({ ...block });
  }, [store, block]);

  const { tags } = props.block;

  const classes = classnames("podcast-collection", {
    [`horizontal`]: tags?.includes("display-hints/podcast-feed-horizontal-all"),
    [`small-thumb`]: tags?.includes("display-hints/podcast-small-thumb"),
  });

  const slug = microsite?.index?.slug || site.index.slug;
  const loader = async () => {
    const episodes: PodcastEpisodeFragment[] = [];
    await Promise.all(
      props.podcasts?.map(podcast =>
        getPodcastEpisodes(
          { id: podcast.id, paging: { take: 10 } },
          store.tags.surrogateKeys,
          `podcast-episodes-${slug}-${podcast.id}`,
        ),
      ),
    ).then(results => results.forEach(items => episodes.push(...items)));
    return episodes
      .sort((a: { startDate: number }, b: { startDate: number }) => b.startDate - a.startDate)
      .slice(0, 10);
  };

  return (
    <>
      <Container className={classes}>
        <Grid columns={props.columns} gap={false}>
          <Remote
            loader={loader}
            cacheKey={`podcasts-collection-${slug}-${block.id}`}
            showLoading={block.id === store?.primaryBlockId}
          >
            {({ data }) =>
              data ? (
                <>
                  {data.map((episode, i: number) => (
                    <Fragment key={episode.id}>
                      {!!props.isHorizontalLayout ? (
                        <PodcastEpisode
                          store={store}
                          id={episode.podcastId}
                          slug={episode.podcastSlug}
                          related_id={episode.id}
                          showDescription={i === 0}
                          hasToggle={true}
                          {...generateEpisodeProps({
                            host: store.env.IMAGE_HOST,
                            episode,
                            store,
                            translator,
                          })}
                        />
                      ) : (
                        <ImageTile
                          {...generateEpisodeProps({
                            host: store.env.IMAGE_HOST,
                            episode,
                            store,
                            translator,
                          })}
                        />
                      )}
                    </Fragment>
                  ))}
                </>
              ) : null
            }
          </Remote>
        </Grid>
      </Container>
      <hr className="divider" />
    </>
  );
});

export default PodcastsEpiSodeCollection;
